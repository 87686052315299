export const translationsEn = {
	welcome:
		'Hello! I am Dubai Chamber’s Virtual Assitant. I am here to help answer your questions regarding Dubai Chamber e-services.',
	headerTitle: 'Dubai Chamber Virtual Assistant',
	live_agent_connect: 'You are now connected to live agent',
	live_agent_disconnect: 'Live Agent has disconnected',
	live_agent_unavailable:
		'Unfortunately, there are no Live Agents online currently. Please leave us a note and we will get back to you.',
	chat_end_title: 'This chat has ended',
	chat_end_description: 'Thank you for chatting with us, please take a minute to rate your experience.',
	transcript: 'Skip and start new chat',
	feedbackHeading:
		'We kindly ask you to answer the questionnaire to measure your level of happiness with Dubai chambers virtual assistant:',

	feedBackQuestionsOne: 'Did the virtual assistant resolve or answer your enquiries at first attempt ?',
	feedBackQuestionsSecond: 'How would you rate your satisfaction with Dubai Chambers virtual assistant ?',
	feedBackQuestionsThird:
		'Overall, how easy was it to get the assistance required from Dubai Chambers virtual assistant ?',
	live_agent_wait: 'Please hold while you are connected with an live agent...',
	live_agent_unavailable_hrs:
		'Our Live Agents are available during business hours, Our Ramadan Hours are Monday – Thursday 8am till 2:00pm & Friday 8am - 11am. You can also leave us a note and we will get back to you.',
	name_error: 'Please input your name!',
	email_error: 'Please enter a valid email!',
	phone_error: 'Please enter a valid phone number!',
	subject_error: 'Subject is required!',
	query_error: 'Query is required!',

	name: 'Name',
	phone: 'Phone',
	email: 'Email',
	get_started: 'Get Started',
	service_down_msg_one: 'Online services are temporarily down',
	service_down_msg_two:
		'We are currently experiencing technical difficulties with our online services, All submitted requests will take approximately one working day to be processed. Appreciate your patience and understanding, our apologizes for any inconvenience caused.',
	greet: 'Hi, there!',
	provide_info: 'Before we begin, Please provide us some information',
	submit: 'Submit',
	info_panel: 'Information Panel',
	agent_assign: 'We are trying to connect to a live agent ...',
};

export const translationsAr = {
	welcome:
		'أهلا! أنا المساعد الافتراضي لغرفة دبي. أنا هنا للمساعدة في الإجابة على أسئلتك المتعلقة بالخدمات الإلكترونية لغرفة دبي.',
	headerTitle: 'المساعد الافتراضي لغرفة دبي',
	live_agent_connect: 'اريد التحدث الى الوكيل الحي',
	live_agent_disconnect: 'تم قطع اتصال الوكيل المباشر',
	live_agent_unavailable:
		'للأسف ، لا يوجد وكلاء مباشرون على الإنترنت حاليًا. يرجى ترك ملاحظة لنا وسنعاود الاتصال بك.',
	chat_end_title: 'انتهت هذه الدردشة',
	feedBackQuestionsOne: 'هل قام المساعد الافتراضي بالرد على الاستفسار من المحاولة الاولى؟',
	feedBackQuestionsSecond: ' كيف تقيم رضاك عن المساعد الافتراضي لغرف دبي؟',
	feedBackQuestionsThird: 'بشكل عام، ما مدى سهولة الحصول على المساعدة المطلوبة من المساعد الافتراضي لغرف دبي؟ ',
	chat_end_description: `نشكرك على استخدامك لخدمة المحادثة الفورية. يرجى تخصيص دقيقة من وقتك، وإخبارنا عن تجربتك مع المساعد
	الإفتراضي`,
	transcript: 'تخطي وابدأ دردشة جديدة',
	agentNotAvailableWarning:
		'نعتذر عن التأخير، جميع وكلاء الخدمة مشغولون حاليا بخدمة عملاء آخرين. الرجاء الانتظار حتى يتوفر وكيل الخدمة او بإمكانكم التواصل لاحقا	',
	agentNotAvailableMsg: 'نعتذر عن إنهاء المحادثة لعدم توفر وكيل خدمة العملاء في الوقت الراهن. الرجاء التواصل لاحقا.',
	feedbackHeading: ' لغرف دبنرجو منك الاجابة على الاستبيان لقياس مستوى سعادتك عن المساعد الافتراضي',
	visitorNotResponsing: 'تم إنهاء هذه الدردشة لأنك لم تقدم أي رد',
	visitorNotResponsingWarning:
		'يبدو أنك لم تكتب منذ فترة طويلة ، وبالتالي فإن هذه المحادثة ستفعل تنتهي		في أقل من دقيقة. في حالة عدم وجود نشاط ، سيتم إغلاق الدردشة',
	feedBackQuestionsOne: 'هل قام المساعد الافتراضي بالرد على الاستفسار من المحاولة الاولى؟',
	feedBackQuestionsSecond: 'كيف تقيم رضاك عن المساعد الافتراضي لغرف دبي؟',
	feedBackQuestionsThird: 'بشكل عام، ما مدى سهولة الحصول على المساعدة المطلوبة من المساعد الافتراضي لغرف دبي؟',
	live_agent_wait: 'يرجى الانتظار أثناء اتصالك بالوكيل المباشر ...',
	live_agent_unavailable_hrs:
		'ساعات العمل: من الاثنين إلى الخميس من الساعة 8 صباحًا حتى 2:00 عصرًا. يوم الجمعة من الساعة 8 صباحًا حتى 11 am ',
	name_error: 'الرجاء إدخال اسمك!',
	email_error: 'يرجى إدخال البريد الإلكتروني الصحيح',
	phone_error: 'يرجى إدخال رقم هاتف صالح',

	name: 'اسم',
	phone: 'هاتف',
	email: 'البريد الإلكتروني',
	subject_error: 'الموضوع مطلوب!',
	query_error: 'مطلوب الاستعلام!',
	get_started: 'البدء',
	greet: 'مرحبا',
	provide_info: 'قبل أن نبدأ ، يرجى تزويدنا ببعض المعلومات',
	submit: 'تقديم',
	info_panel: 'لوحة المعلومات',
	agent_assign: '... نحن نحاول الاتصال بوكيل مباشر',
};
