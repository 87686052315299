import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MessageList from '../../components/MessageList';
import UserInput from '../../components/UserInput';
import Header from '../../components/Header';
import { Row, Col, Collapse, Card, Button } from 'antd';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import { MinusOutlined } from '@ant-design/icons';
import axios from 'axios';
// import { feedback } from '../../../config/development.json';
import moment from 'moment';

const { Panel } = Collapse;

const ref = React.createRef();

class ChatWindow extends Component {
	//how to define state in class component in react ?

	constructor(props) {
		super(props);
	}

	onUserInputSubmit(message) {
		this.props.onUserInputSubmit(message);
	}

	onFilesSelected(filesList) {
		this.props.onFilesSelected(filesList);
	}

	// how to set tomorrow date in js ?

	//Source: https://stackoverflow.com/questions/23081158

	render() {
		let messageList = this.props.messageList || [];
		let classList = ['sc-chat-window', this.props.isOpen ? 'opened' : 'closed'];
		return (
			<div className={classList.join(' ')}>
				{this.props.modalVisible ? (
					<div className='chat-modal welcome-message-wrapper'>
						{this.props.modalContent}
						<Button
							shape='circle'
							size='medium'
							className='header-button minimize'
							onClick={() => this.props.handleClick()}
						>
							<MinusOutlined style={{ fontSize: '18px' }} />
						</Button>
					</div>
				) : (
					<Row className='row-wrapper'>
						<Col span={24}>
							<Header
								downloadTanscript={this.props.downloadTanscript}
								onEndChat={this.props.onEndChat}
								teamName={this.props.agentProfile.teamName}
								imageUrl={this.props.agentProfile.imageUrl}
								onClose={this.props.onClose}
								arabicMode={this.props.arabicMode}
								handleClick={this.props.handleClick}
								textControl={this.props.textControl}
								chat={this.props.chat}
							/>
							<Card className='sub-header-wrapper'>
								<div className='sub-header'>
									<span>
										{this.props.arabicMode
											? 'ساعات العمل: من الاثنين إلى الخميس من الساعة 8 صباحًا حتى 2:00 عصرًا. يوم الجمعة من الساعة 8 حتى 11 صباحًا'
											: `Our Ramadan Timings are from 08:00 am to 02:00 pm Monday to Thursday. Friday 08:00 am to 11:00 am.`}
									</span>
								</div>
							</Card>
						</Col>
						<Col span={16} className='row-wrapper chat-content-flex'>
							<MessageList
								messages={messageList}
								imageUrl={this.props.agentProfile.imageUrl}
								arabicMode={this.props.arabicMode}
								sendMessage={this.props.sendMessage}
								ref={ref}
							/>
							<UserInput
								onSubmit={this.onUserInputSubmit.bind(this)}
								onFilesSelected={this.onFilesSelected.bind(this)}
								showEmoji={this.props.showEmoji}
								arabicMode={this.props.arabicMode}
								onAudioInput={this.props.onAudioInput}
								textControl={this.props.textControl}
								loading={this.props.loading}
								onFileAttach={this.props.onFileAttach}
							/>
						</Col>
						<Col span={8} className='padding-sidebar'>
							<div className='sidebar-wrapper'>
								<div className='sidebar-title'>
									{this.props.arabicMode ? `لوحة المعلومات` : `Information Panel`}
								</div>
								<div className='sidebar-subtitle'>
									{this.props.arabicMode
										? _.get(this.props.tabs, [0, 'Keyword_Ar'])
										: _.upperFirst(_.get(this.props.tabs, [0, 'Keyword_En']))}
								</div>

								<Scrollbars
									className={
										this.props.arabicMode ? 'arabic-sider sidebar-content' : 'sidebar-content'
									}
								>
									<Collapse accordion>
										{_.map(_.filter(_.slice(this.props.tabs, 0, 4), 'label'), (each, index) => {
											const finalText = _.get(each, 'Answer', '').replace(
												/(\b(https?|ftp|file):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z0-9+&@#\/%=~_|])/gim,
												'<a target="_blank" href="$1">$1</a>',
											);
											return (
												<Panel header={each.label} key={_.toString(index)}>
													<div
														className='links-content'
														dangerouslySetInnerHTML={{ __html: _.get(each, 'Answer', '') }}
													></div>
												</Panel>
											);
										})}
									</Collapse>
								</Scrollbars>
							</div>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

ChatWindow.propTypes = {
	agentProfile: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFilesSelected: PropTypes.func,
	onUserInputSubmit: PropTypes.func.isRequired,
	showEmoji: PropTypes.bool,
};

export default React.memo(ChatWindow);
