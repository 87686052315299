import React, { useEffect } from 'react';
import moment from 'moment';
import 'rc-texty/assets/index.css';
import chatbotImg from '../../assets/chatbot.jpg';

const TextMessage = (props) => {
	useEffect(() => {
		for (let i = 0; i <= 100; i++) {
			const head = `highlighter-${i}`;
			if (document.getElementById(head)) {
				document.getElementById(head).addEventListener('click', function() {
					const text = document.getElementById(head).innerHTML;
					props.sendMessage(text, text);
					document.getElementById(head).replaceWith(text);
					document.getElementById(head).id = Math.floor(Math.random() * 100000);
				});
			}
		}
	});

	return (
		<div style={{ width: '100%' }}>
			{props.author === 'me' ? (
				<div className='send-message-wrapper'>
					<div className='send-message-header'>
						<div className='avatar-wrapper'>
							<img
								src={'https://chatbotva.dubaichamber.com/images/admin.png'}
								alt='Avatar'
								className='avatar'
							></img>
						</div>
						<div className='send-message-text-wrapper'>
							<div>
								<div className='send-message-title'>{props.user}</div>
							</div>
							<div
								className={
									props.arabicMode ? 'send-message-text left-top' : 'send-message-text right-top'
								}
							>
								<div>{props.data.text}</div>
								<div className='send-message-footer'>
									<div className='send-message-footer-wrapper'>
										<div className='footer-time'>{moment().format('LT')}</div>
										<div></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='recevie-message-wrapper'>
					<div className='recevie-message-header'>
						<div className='avatar-wrapper'>
							<img
								src={'https://chatbotva.dubaichamber.com/images/chatbot.jpg'}
								alt='Avatar'
								className='avatar'
							></img>
						</div>
						<div className='receive-message-text-wrapper'>
							<div>
								<div className='receive-message-title'>{props.user}</div>
							</div>
							<div
								className={
									props.arabicMode
										? 'receive-message-text right-top'
										: 'receive-message-text left-top'
								}
							>
								<div style={{ whiteSpace: 'pre-wrap' }}>{props.data.text}</div>
								<div className='receive-message-footer'>
									<div className='receive-message-footer-wrapper'>
										<div></div>
										<div className='footer-time'>{moment().format('LT')}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default TextMessage;
